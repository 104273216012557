import React, {Component} from "react"

import {Model} from "Model"
import {KlassDropdown} from "../KlassDropdown"
import {SelectOption} from "data/DataStore"
import styles from "./Select.module.css"

interface Props {
    id: string
    model: Model
    options: SelectOption[]
    onChange: (id: string, value: string) => void
    errors?: {[key: string]: string}
}

export class Select extends Component<Props, {}> {
    public render() {
        const {id, model, options, onChange, errors} = this.props
        const json_data = model.document.json_data
        const error = errors[id]

        options.unshift({label: "--  select  --", value: ""})

        return (
            <div>
                <KlassDropdown
                    onChange={(el) => {
                        onChange(id, el.value)
                    }}
                    options={options}
                    value={options.filter((el) => {
                        return el.value === json_data[id]
                    })}
                />
                {error && <p className={styles.error}>{error} </p>}
            </div>
        )
    }
}
