import React, {Component} from "react"
import styles from "./VaccinationQuestion.module.css"
import {LabelRadio} from "components/inputs/LabelRadio"
import {RadioButton} from "components/inputs/RadioButton"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"

interface Props {
    model: Model
}
const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    vaccinated: mandatory
}

@observer
export class VaccinationQuestion extends Component<Props, {}> {
    private beenVaccinated = "vaccinated"

    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate EmergencyContact")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private onChange(id: string, value) {
        this.json_data[id] = value

        this.forceUpdate()

        this.props.model.dispatcher2.dispatch()
    }

    public render() {
        const r = {onChange: this.onChange, json_data: this.json_data, errors: this.errors}
        return (
            <div className={styles.root}>
                {/* <h2 className={styles.h2}>VACCINATION POLICY</h2> */}

                <h2 className={styles.h3}>VACCINATION POLICY</h2>

                <div className={styles.form}>
                    <LabelRadio
                        text="Have you been vaccinated for covid-19"
                        error={this.errors[this.beenVaccinated]}
                        className={styles.labelRadio}
                        required>
                        <RadioButton
                            label="Yes"
                            value="yes"
                            group_name={this.beenVaccinated}
                            {...r}
                        />
                        <RadioButton
                            label="No"
                            value="no"
                            group_name={this.beenVaccinated}
                            {...r}
                        />
                    </LabelRadio>
                </div>
            </div>
        )
    }
}
