import React, {Component} from "react"
import styles from "./NavigationButton.module.css"
import {Model} from "Model"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import classNames from "classnames"
import {ReactComponent as Icon} from "./parts/img/icon.svg"
import {computed, observable} from "mobx"
import {isEnableSubmitButton} from "helper/Methods"

interface Props {
    model: Model
    className?: string
    setTabForms?: Function
}

@observer
export class NavigationButton extends Component<Props, {}> {
    private DISABILITIE_YES = "yes"

    @computed
    private get nextButtonEnabled(): boolean {
        const model = this.props.model
        if (model.getNextFormId() === null) {
            return false
        }
        return true
    }

    @observable
    private submitButtonEnabled = true

    @computed
    private get saveButtonEnabled(): boolean {
        const model = this.props.model
        if (model.form.type === "pdf" && !model.iframeLoaded) {
            return false
        }
        return true
    }

    public render() {
        const {model, className} = this.props
        const {
            document: {json_data}
        } = model
        const {DISABILITIE_YES} = this
        return (
            <div className={classNames(styles.root, className)}>
                <button
                    className={styles.button}
                    disabled={model.getPreviousFormId() === null}
                    onClick={() => {
                        const form_id: FormID = model.getPreviousFormId()
                        model.setFormId(form_id)
                    }}>
                    <Icon className={styles.icon} width="12" height="12" />
                    BACK
                </button>

                {this.nextButtonEnabled && (
                    <>
                        <button
                            className={classNames(styles.button, styles.buttonSave)}
                            onClick={async () => {
                                const success = await model.preSaveForm()
                                if (!success) {
                                    // alert("Please check errors")
                                }
                                this.props.setTabForms()
                            }}>
                            SAVE
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "signature"
                                }
                            }}>
                            My Signature
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "initials"
                                }
                            }}>
                            My Initials
                        </button>
                        {/* <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "parent"
                                }
                            }}>
                            Parent Singature
                        </button> */}
                        <button
                            className={classNames(styles.button, styles.buttonNext)}
                            disabled={
                                json_data.disabilites && json_data.disabilites === DISABILITIE_YES
                                    ? this.saveButtonEnabled
                                    : !this.saveButtonEnabled
                            }
                            onClick={async () => {
                                const form_id: FormID = model.getNextFormId()

                                const success = await model.save()
                                if (success) {
                                    model.setFormId(form_id)
                                } else {
                                    // alert("Please check errors")
                                }
                            }}>
                            SAVE & CONTINUE <Icon className={styles.icon} width="12" height="12" />
                        </button>
                    </>
                )}

                {!this.nextButtonEnabled && (
                    <button
                        className={classNames(styles.button, styles.buttonNext)}
                        disabled={
                            json_data && json_data.isSubmitted
                                ? true
                                : isEnableSubmitButton(json_data, model.getForms())
                        }
                        onClick={async () => {
                            this.submitButtonEnabled = false
                            await model.submit()
                            // if (!success) {
                            //     alert("Can not submit")
                            //     return
                            // }
                            this.forceUpdate()
                        }}>
                        SUBMIT <Icon className={styles.icon} width="12" height="12" />
                    </button>
                )}
            </div>
        )
    }
}
