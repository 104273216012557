import {Model} from "./Model"
import {
    CHECKED_YES,
    ENROLLMENT_PROGRAMS,
    CAMPUS_EA_EMP_APC,
    CORE_PROGRAMS_DISABLED,
    YES,
    NO
} from "./helper/Methods"

export class ModelListener {
    private cache = {}

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private onModel() {
        const json_data = this.model.document.json_data

        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)
        const enableTab = this.model.enableTabs.bind(this.model)
        const disableTab = this.model.disableTabs.bind(this.model)

        const [
            radioAndTelevision,
            hispanicRadionAndTelevision,
            digitalMediaProgramRTB,
            digitalMediaProgram
        ] = ENROLLMENT_PROGRAMS
        const [lombard, chicago, miami] = CAMPUS_EA_EMP_APC
        const [, aasBroadCasting, , , , aasGedEd, aasNonSports] = CORE_PROGRAMS_DISABLED
        const {campus} = json_data
        if (campus === lombard || campus === chicago) {
            enableTab("vaccination_policy")
            enable("application_vaccination_question")
            disable("application_upload_docs")
            disable("vaccination_policy")
            if (!json_data.vaccinated) {
                disable("application_upload_docs")
                disable("vaccination_policy")
            }
            if (json_data.vaccinated && json_data.vaccinated === YES) {
                enable("application_upload_docs")
                disable("vaccination_policy")
            }
            if (json_data.vaccinated && json_data.vaccinated === NO) {
                disable("application_upload_docs")
                enable("vaccination_policy")
            }
        } else {
            disableTab("vaccination_policy")
            disable("application_vaccination_question")
            disable("application_upload_docs")
            disable("vaccination_policy")
        }
        if (json_data.extra && json_data.extra.campus) {
            const {corePlus, corePlusPlus, program, campus} = json_data
            if (
                program === radioAndTelevision ||
                program === digitalMediaProgramRTB ||
                program === digitalMediaProgram
            ) {
                // || program === aasGedEd || program === aasNonSports
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                if (campus === lombard || campus === chicago) {
                    enable("enrollment_agreement_rtb_lc")
                } else if (campus === miami) {
                    enable("enrollment_agreement_rtb_miami")
                } else {
                    enable("enrollment_agreement_rtb")
                }
                if (corePlus && corePlus === CHECKED_YES) {
                    if (campus === lombard || campus === chicago) {
                        enable("enrollment_agreement_emp_apc_lc")
                    } else if (campus === miami) {
                        enable("enrollment_agreement_emp_apc_miami")
                    } else {
                        enable("enrollment_agreement_emp_apc")
                    }
                } else if (corePlusPlus && corePlusPlus === CHECKED_YES) {
                    enable("enrollment_agreement_aas")
                    if (campus === lombard || campus === chicago) {
                        enable("enrollment_agreement_emp_apc_lc")
                    } else if (campus === miami) {
                        enable("enrollment_agreement_emp_apc_miami")
                    } else {
                        enable("enrollment_agreement_emp_apc")
                    }
                }
            } else if (program === hispanicRadionAndTelevision) {
                enable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                if (corePlus && corePlus === CHECKED_YES) {
                    if (campus === lombard || campus === chicago) {
                        enable("enrollment_agreement_emp_apc_lc")
                    } else if (campus === miami) {
                        enable("enrollment_agreement_emp_apc_miami")
                    } else {
                        enable("enrollment_agreement_emp_apc")
                    }
                } else if (corePlusPlus && corePlusPlus === CHECKED_YES) {
                    enable("enrollment_agreement_aas")
                    if (campus === lombard || campus === chicago) {
                        enable("enrollment_agreement_emp_apc_lc")
                    } else if (campus === miami) {
                        enable("enrollment_agreement_emp_apc_miami")
                    } else {
                        enable("enrollment_agreement_emp_apc")
                    }
                }
            } else if (aasBroadCasting === program) {
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                enable("enrollment_agreement_aas")
            } else if (aasGedEd === program) {
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                enable("enrollment_agreement_aas")
            } else if (aasNonSports === program) {
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                if (campus === lombard || campus === chicago) {
                    enable("enrollment_agreement_emp_apc_lc")
                } else if (campus === miami) {
                    enable("enrollment_agreement_emp_apc_miami")
                } else {
                    enable("enrollment_agreement_emp_apc")
                }
                enable("enrollment_agreement_aas")
            } else {
                disable("enrollment_agreement_rtb")
                disable("enrollment_agreement_rtb_lc")
                disable("enrollment_agreement_hispanic_rtb")
                disable("enrollment_agreement_rtb_miami")
                disable("enrollment_agreement_emp_apc")
                disable("enrollment_agreement_emp_apc_lc")
                disable("enrollment_agreement_emp_apc_miami")
                disable("enrollment_agreement_aas")
                if (campus === lombard || campus === chicago) {
                    enable("enrollment_agreement_emp_apc_lc")
                } else if (campus === miami) {
                    enable("enrollment_agreement_emp_apc_miami")
                } else {
                    enable("enrollment_agreement_emp_apc")
                }
                if (corePlusPlus && corePlusPlus === CHECKED_YES) {
                    enable("enrollment_agreement_aas")
                }
            }
        } else {
            disable("enrollment_agreement_rtb")
            disable("enrollment_agreement_rtb_lc")
            disable("enrollment_agreement_hispanic_rtb")
            disable("enrollment_agreement_rtb_miami")
            disable("enrollment_agreement_emp_apc")
            disable("enrollment_agreement_emp_apc_lc")
            disable("enrollment_agreement_emp_apc_miami")
            disable("enrollment_agreement_aas")
        }

        disable("identity_authentication")
        disable("online_education_disclosure")
        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")
        disable("transferability_of_credits")
        disable("him_enrollment_agreement")
        disable("recruitment")
        disable("covid_19")

        this.onCampusChange()
        this.showProgramForms()
        this.onStateChange()

        if (json_data.student_athlete === "basketball") {
            enable("recruitment")
        } else {
            disable("recruitment")
        }

        let him17Campuses = [
            "ANU - Youngstown, OH",
            "ANU - Charlottesville, VA",
            "ANU - Danville, VA",
            "ANU - Harrisonburg, VA",
            "ANU - Lynchburg, VA",
            "ANU - Roanoke Valley, VA",
            "ANU - Dayton Area, OH",
            "ANU - Online Division"
        ]

        if (
            json_data.program_name === "Health Information Management" &&
            him17Campuses.indexOf(json_data.campus) !== -1
        ) {
            enable("him_enrollment_agreement")
        } else {
            disable("him_enrollment_agreement")
        }

        // URGENT FIX
        // Please check relation with him17Campuses up
        if (json_data.program_name === "Health Information Management") {
            enable("him_enrollment_agreement")
        }

        if (json_data.program_name === "CDL Training") {
            // enable(15)
            // enable(5)
            // enable(6)
            // disable(4)
            // disable(2)
            // disable(12)
        } else {
            enable("felony_acknowledgement")
            enable("education_disclosure")
            enable("transcript_request")
        }

        if (
            json_data.program_name === "Pharmacy Technician" ||
            json_data.program_name === "Pharmacy Technician (Associate Program)"
        ) {
            enable("covid_19")
        }
    }

    private onCampusChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("identity_authentication")
        disable("online_education_disclosure")
        // disable(15)
        disable("covid_19")

        if (this.model.document.json_data.campus === "ANU - Distance Education Division") {
            enable("identity_authentication")
            enable("online_education_disclosure")
        }
    }

    private onStateChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("transferability_of_credits")
        disable("immunization_verification")

        if (this.model.document.json_data.state === "Tennessee") {
            enable("transferability_of_credits")
            enable("immunization_verification")
        }
    }

    private showProgramForms() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")

        let program = this.model.document.json_data.program_name

        if (program == null) {
            return
        }

        if (this.validateProgramTS(program)) {
            enable("technical_standards")
        }

        if (this.validateProgramHIM(program)) {
            enable("him_technical_standards")
        }

        if (this.validateProgramCPA(program)) {
            enable("cpa_eligibility")
        }
    }

    private validateProgramTS(program: string) {
        let programsArray = [
            "Surgical Technology & Central Sterile Processing",
            "Medical Assisting Associate",
            "Phlebotomy and ECG Technician",
            "Medical Assisting (Certificate Program)"
        ]

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }

    private validateProgramHIM(program) {
        if (program === "Health Information Management") {
            return true
        }
        return false
    }

    private validateProgramCPA(program) {
        let programsArray = [
            "Business Administration - Accounting (Bachelor Program)",
            "Business Administration - Accounting (Associate Program)",
            "Accounting (Diploma Program)"
        ]

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }
}
