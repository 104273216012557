import React, {Component} from "react"
import styles from "./School.module.css"

interface Props {
    school: any
}

export class School extends Component<Props, {}> {
    public render() {
        const {
            school: {name, url, campuses}
        } = this.props

        return (
            <div className={styles.root}>
                <div className={styles.state}>
                    {url ? (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            <h4 className={styles.title}>{name}</h4>
                        </a>
                    ) : (
                        <h4 className={styles.title}>{name}</h4>
                    )}
                    {campuses.map((campus, i) => (
                        <div className={styles.campus} key={i + 1}>
                            {campus.url && (
                                <a href={campus.url} target="_blank" rel="noopener noreferrer">
                                    <span className="footerschool">{campus.name}</span> <br />
                                </a>
                            )}
                            <a href={campus.location} target="_blank" rel="noopener noreferrer">
                                {campus.direction1}
                                <br />
                                {campus.direction2}
                                <br />
                                {campus.direction3 && (
                                    <div>
                                        {campus.direction3} <br />
                                    </div>
                                )}
                            </a>
                            <b>
                                <a href={`tel:${campus.phone}`}>{campus.phoneText}</a>
                            </b>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
