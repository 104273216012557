import React, {Component} from "react"
import styles from "./SubmissionForm.module.css"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import moment from "moment"

interface Props {
    model: Model
}

const rules = {}

@observer
export class SubmissionForm extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate EmploymentDetails")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value

        this.forceUpdate()
    }

    public render() {
        const p = {
            model: this.props.model,
            onChange: this.onChange,
            errors: this.errors,
            json_data: this.json_data
        }

        let message = "Please click the submit button below to complete your submission"
        if (p.model.document.json_data && p.model.document.json_data.isSubmitted) {
            message = "Congratulations on taking the next step in advancing your career!"
        }

        return (
            <section className={`section-content ${styles.infoContainer}`}>
                <div className={styles.root}>
                    <h3 className={classNames(styles.h3, styles.hidden)}>Submit Form</h3>
                    <div style={{marginTop: "60px", marginBottom: "60px"}}>{message}</div>
                    {this.json_data.isSurveyQuizAnswered === true && (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <table className={`table table-sm ${styles.styleTable}`}>
                                        <tbody>
                                            <tr className="d-flex">
                                                <td className="col-sm-5">
                                                    <b>Started:</b>
                                                </td>
                                                <td className="col-sm-7">
                                                    {moment().format("dddd, MMMM Do, YYYY h:mm a")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>IP Address:</b>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {}
                            {this.json_data.surveyQuizResults &&
                                this.json_data.surveyQuizResults.length > 0 &&
                                this.json_data.surveyQuizResults.map(
                                    (
                                        {
                                            correct,
                                            incorrect,
                                            percentageCorrect,
                                            totalCorrectPoints,
                                            totalQuestions
                                        },
                                        i
                                    ) => (
                                        <div className={styles.resultsTable} key={i}>
                                            <h4 className={styles.surveyTitle}>
                                                Survery Quiz Result #{i + 1}
                                            </h4>
                                            <div className={styles.wrapper}>
                                                <div className={styles.container1}></div>

                                                <div className={styles.container2}>
                                                    <span className={styles.scoreSpan}>
                                                        {percentageCorrect} % <br /> Score
                                                    </span>
                                                </div>

                                                <div className={styles.container3}>
                                                    <span className={styles.scoreSpan}>
                                                        {totalCorrectPoints}/30 <br /> Points
                                                    </span>
                                                </div>

                                                <div className={styles.container4}></div>
                                            </div>

                                            <h2 className={styles.sectionTitleQuiz}>
                                                QUIZ RESULTS
                                            </h2>
                                            <div className={styles.wrapper}>
                                                <div className={styles.container5}>
                                                    <span className={styles.totalsSpan}>
                                                        Correct <br /> {correct}
                                                    </span>
                                                </div>

                                                <div className={styles.container6}>
                                                    <span className={styles.totalsSpan}>
                                                        Incorrect <br /> {incorrect}
                                                    </span>
                                                </div>

                                                <div className={styles.container8}>
                                                    <span className={styles.totalsSpan}>
                                                        Total Questions <br /> {totalQuestions}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </React.Fragment>
                    )}
                </div>
            </section>
        )
    }
}
