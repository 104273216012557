import React, {Component} from "react"
import styles from "./CustomCheckBox.module.css"
import {JsonDocument} from "Model"

interface Props {
    label: string
    id: string
    value: string
    json_data: JsonDocument
    onChange: (id, value) => void
    disabled?: boolean
}

export class CustomCheckBox extends Component<Props, {}> {
    public render() {
        const {id, value, json_data, onChange, label, disabled} = this.props
        let checked: boolean = json_data[id] === "yes"
        return (
            <label className={styles.root} htmlFor={id}>
                <input
                    type="checkbox"
                    id={id}
                    value={value}
                    checked={checked}
                    onChange={(event) => {
                        let value: "yes" | "no" = "yes"
                        if (event.target.checked) {
                            value = "yes"
                        }
                        onChange(id, value)
                    }}
                    disabled={disabled}
                />
                <p className={`${styles.text}`}>{label}</p>
            </label>
        )
    }
}
