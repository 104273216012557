import * as React from "react"
import * as ReactDOM from "react-dom"
import "./index.css"
import {Model} from "./Model"
import {App} from "./App"
import {GateListener} from "./lib/gate/GateListener"
import {createBrowserHistory} from "history"
import {Gate} from "./lib/gate/Gate"
import {Router as ReactRouter} from "react-router"
import {getParameterByName} from "./lib/functions/getParameterByName"
import {Bridge} from "./Bridge"
import {DataStore} from "./data/DataStore"
import {json} from "./data/data"
import {ModelListener} from "./ModelListener"

document.domain = document.domain.replace("web.", "")
const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const dataStore = new DataStore(json)
const model = new Model(gate, history, dataStore)

async function main() {
    const token = getParameterByName("token")
    const user_id = getParameterByName("user_id")
    console.log("TOKEN=" + token)
    console.log("USER_ID=" + user_id)
    gate.setToken(token)
    await model.loadUser(parseInt(user_id))
    model.setFormId(model.form.form_id) // changing for first time the form here
}
main()

window.addEventListener("admissions-pdf-loaded", () => {
    console.log("!!!!! admissions-pdf-loaded")
})

// @ts-ignore
window.model = model

ReactDOM.render(
    <ReactRouter history={history}>
        <App model={model} gate={gate} />
    </ReactRouter>,
    document.getElementById("root")
)

// @ts-ignore
window.bridge = new Bridge(model)

new ModelListener(model)

// setTimeout(() => {
//     model.disableForm("application")
// }, 3000)
//
// setTimeout(() => {
//     model.enableForm("application")
// }, 6000)
