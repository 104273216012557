import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {SelectOption} from "data/DataStore"
import {JsonDocument, Model, CareerDocument} from "../../Model"
import {Campus, Program, Advisor, CareerDate} from "data/interfaces"
import {observable} from "mobx"
import {Select} from "components/inputs/Select"
import {LabelRadio} from "components/inputs/LabelRadio"
import {RadioButton} from "components/inputs/RadioButton"
import {CheckBox} from "components/inputs/CheckBox"
import {CustomCheckBox} from "components/inputs/CustomCheckBox"
import {Disclosure} from "components/Disclosure"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"
import {formatStandarUSDate, CORE_PROGRAMS_DISABLED, CHECKED_YES} from "helper/Methods"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const originalRules = {
    program: mandatory,
    start_date: mandatory,
    campus: mandatory,
    advisor: mandatory,
    has18: mandatory
}

let rules = {
    ...originalRules
}

const rulesCorePlusProgram = {
    corePlusProgram: mandatory
}

const rulesCorePlusPlusProgram = {
    corePlusPlusProgram: mandatory
}

const parentRules = {
    parent_name: mandatory,
    parent_email: {
        ...mandatory,
        email: {
            email: true
        }
    }
}

@observer
export class CareerInformation extends Component<Props, {}> {
    private has18Key = "has18"

    private PARENT_KEY = {
        name: "parent_name",
        email: "parent_email"
    }

    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}
    private career_data: CareerDocument
    private career_campuses: any[] = []
    private career_programs: any[] = []
    private career_advisors: any[] = []
    private career_corePlus: any[] = []
    private career_corePlusPlus: any[] = []
    private career_aas_non_sports: any[] = []
    private career_dates: any[] = []
    private CAREER_KEYS = ["campus", "program", "advisor", "year", "campuses"]
    private EXTRA_INFO = "extra"
    private START_DATE = "start_date"
    private CORE_EMPHASIS_PROGRAMS = [
        "corePlus",
        "corePlusProgram",
        "corePlusPlus",
        "corePlusPlusProgram"
    ]
    private CORE_EMPHASIS_PROGRAMS_SELECTED = [
        "corePlusProgramSelected",
        "corePlusPlusProgramSelected"
    ]
    private CORE_EMPHASIS_CHECKED = "yes"
    private NON_SPORTS = {
        key: "nonSports",
        checked: "yes"
    }

    constructor(props) {
        super(props)
        console.log("Inside constructor CareerInformation")

        this.onChange = this.onChange.bind(this)
        this.onInitFillSelectOptions = this.onInitFillSelectOptions.bind(this)
        this.onModel = this.onModel.bind(this)
        this.json_data = props.model.document.json_data
        if (!this.json_data[this.EXTRA_INFO]) {
            this.initExtraInfo() // todo: careful with this.
        }
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    private findByKey(list, keyField) {
        return list.find(({code}) => code === keyField)
    }
    private initExtraInfo() {
        const extra = this.EXTRA_INFO
        this.json_data[extra] = {}
    }

    private addExtraInfoCampusProgramYear() {
        const [campus, program, advisor, yearInfo, campusesInfo] = this.CAREER_KEYS
        const [, assBroadCasting, , , , aasGenEd, aasNonSports] = CORE_PROGRAMS_DISABLED
        const [
            corePlusProgramSelected,
            corePlusPlusProgramSelected
        ] = this.CORE_EMPHASIS_PROGRAMS_SELECTED
        const [, corePlusProgram, , corePlusPlusProgram] = this.CORE_EMPHASIS_PROGRAMS
        const extra = this.EXTRA_INFO
        const fieldCampus = this.json_data[campus]
        const fieldProgram = this.json_data[program]
        const fieldAdvisor = this.json_data[advisor]
        if (fieldCampus && fieldProgram) {
            const {campuses, year} = this.career_data
            const foundCampus = this.findByKey(campuses, fieldCampus)
            if (foundCampus) {
                const {programs, advisors, programsCorePlus, programsCorePlusPlus} = foundCampus
                const foundProgram = this.findByKey(programs, fieldProgram)
                const foundAdvisor = advisors.find(({email}) => email === fieldAdvisor)
                if (foundProgram) {
                    this.json_data[extra][campus] = foundCampus
                    this.json_data[extra][program] = foundProgram
                    this.json_data[extra][advisor] = foundAdvisor
                    this.json_data[extra][yearInfo] = year
                    this.json_data[extra][campusesInfo] = this.props.model.campusesInfo
                    // todo only for aas programs as a core program
                    const programSelected = this.json_data[extra][program]
                    if (assBroadCasting === this.json_data[program]) {
                        this.json_data[extra][corePlusPlusProgramSelected] = programSelected
                        this.json_data[extra][corePlusPlusProgramSelected].label =
                            programSelected.code
                    }
                    if (aasGenEd === this.json_data[program]) {
                        this.json_data[extra][corePlusPlusProgramSelected] = programSelected
                        this.json_data[extra][corePlusPlusProgramSelected].label =
                            programSelected.code
                    }
                    if (aasNonSports === this.json_data[program]) {
                        this.json_data[extra][corePlusPlusProgramSelected] = programSelected
                        this.json_data[extra][corePlusPlusProgramSelected].label =
                            programSelected.code
                    }
                    if (this.json_data[corePlusProgram]) {
                        this.json_data[extra][corePlusProgramSelected] = programsCorePlus.find(
                            ({code}) => code === this.json_data[corePlusProgram]
                        )
                        this.json_data[extra][corePlusProgramSelected].label = this.json_data[
                            extra
                        ][corePlusProgramSelected].code
                    }
                    if (this.json_data[corePlusPlusProgram]) {
                        this.json_data[extra][
                            corePlusPlusProgramSelected
                        ] = programsCorePlusPlus.find(
                            ({code}) => code === this.json_data[corePlusPlusProgram]
                        )
                        this.json_data[extra][corePlusPlusProgramSelected].label = this.json_data[
                            extra
                        ][corePlusPlusProgramSelected].code
                    }
                }
            }
        }
    }

    public onInitFillSelectOptions() {
        const [campus, program, advisor] = this.CAREER_KEYS
        const [sportsBroadCasting] = CORE_PROGRAMS_DISABLED
        this.career_data = this.props.model.careerInfo
        if (this.career_data) {
            // console.log('this.career_data:', toJS( this.career_data));
            this.career_campuses = this.fillSelectOption(this.career_data.campuses)
            if (this.json_data[campus]) {
                const campusFound = this.career_data.campuses.find(
                    ({code}) => code === this.json_data[campus]
                )
                // console.log('campus found:', toJS(campusFound));

                if (campusFound) {
                    this.career_programs = this.fillSelectOption(campusFound.programs)
                    this.career_advisors = this.fillAdivisors(campusFound.advisors)
                    this.career_corePlus = this.fillSelectOption(campusFound.programsCorePlus)
                    this.career_corePlusPlus = this.fillSelectOption(
                        campusFound.programsCorePlusPlus
                    )
                    const aasSportBroadCasting = campusFound.programsCorePlus.filter(
                        ({code}) => code === sportsBroadCasting
                    )
                    this.career_aas_non_sports = this.fillSelectOption(aasSportBroadCasting)
                    if (this.json_data[program]) {
                        const programFound = campusFound.programs.find(
                            ({code}) => code === this.json_data[program]
                        )
                        // console.log('program found:', toJS(programFound));
                        this.career_dates = this.fillCareerDates(programFound.career_dates.dates)
                        // console.log('this.career_dates:', this.career_dates);
                    }
                    this.addExtraInfoCampusProgramYear()
                } else {
                    this.career_programs = []
                    this.career_advisors = []
                    this.career_dates = []
                    this.json_data[program] = ""
                    this.json_data[advisor] = ""
                    this.career_corePlus = []
                    this.career_corePlusPlus = []
                }
            } else {
                this.career_programs = []
                this.career_advisors = []
                this.career_dates = []
                this.json_data[program] = ""
                this.json_data[advisor] = ""
                this.career_corePlus = []
                this.career_corePlusPlus = []
            }
            this.onInitRules()
        }
    }

    private onInitRules() {
        const [corePlus, , corePlusPlus] = this.CORE_EMPHASIS_PROGRAMS
        const [, , , , , , aasNonSports] = CORE_PROGRAMS_DISABLED
        if (this.json_data[corePlus] === this.CORE_EMPHASIS_CHECKED) {
            rules = {
                ...originalRules,
                ...rulesCorePlusProgram
            }
        } else if (this.json_data[corePlusPlus] === this.CORE_EMPHASIS_CHECKED) {
            rules = {
                ...originalRules,
                ...rulesCorePlusProgram,
                ...rulesCorePlusPlusProgram
            }
        } else if (
            this.json_data.program === aasNonSports &&
            this.json_data.nonSports &&
            this.json_data.nonSports === this.NON_SPORTS.checked
        ) {
            rules = {
                ...originalRules,
                ...rulesCorePlusProgram
            }
        } else {
            rules = {
                ...originalRules
            }
        }
    }

    public async componentDidUpdate() {
        this.onInitFillSelectOptions()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
        this.onInitFillSelectOptions()
        this.forceUpdate()
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        if (this.json_data[this.has18Key] && this.json_data[this.has18Key] !== CHECKED_YES) {
            rules = {...rules, ...parentRules}
        } else {
            delete rules[this.PARENT_KEY.email]
            delete rules[this.PARENT_KEY.name]
        }
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private fillCareerDates(list): SelectOption[] {
        return list.map((el: CareerDate) => {
            const formattedDate = formatStandarUSDate(el.start)
            return {value: el.start, label: formattedDate}
        })
    }

    private fillSelectOption(list): SelectOption[] {
        return list.map((el: Campus | Program) => {
            return {id: el.id, value: el.code, label: el.name}
        })
    }

    private fillAdivisors(list): SelectOption[] {
        return list.map((el: Advisor) => {
            return {id: el.id, value: el.email, label: `${el.name} (${el.email})`}
        })
    }

    private resetEmphisisCheckeds(id) {
        const [corePlus, , corePlusPlus] = this.CORE_EMPHASIS_PROGRAMS
        const {key, checked} = this.NON_SPORTS
        const [, , , , , , aasNonSports] = CORE_PROGRAMS_DISABLED
        const [, keyProgram] = this.CAREER_KEYS
        this.json_data[corePlus] = ""
        this.json_data[corePlusPlus] = ""
        if (id === keyProgram && this.json_data.program === aasNonSports) {
            this.json_data[key] = checked
        } else {
            delete this.json_data[key]
        }
    }

    private resetEmphasisSelects(id, value) {
        const [, corePlusProgram, , corePlusPlusProgram] = this.CORE_EMPHASIS_PROGRAMS
        // const [, aasBroadCasting, , , , aasGenEd] = CORE_PROGRAMS_DISABLED
        // const [, program] = this.CAREER_KEYS
        const [
            corePlusProgramSelected,
            corePlusPlusProgramSelected
        ] = this.CORE_EMPHASIS_PROGRAMS_SELECTED
        const extra = this.EXTRA_INFO
        delete this.json_data[corePlusProgram]
        delete this.json_data[corePlusPlusProgram]
        delete this.json_data[extra][corePlusProgramSelected]
        delete this.json_data[extra][corePlusPlusProgramSelected]
        // if (id === program && value !== aasBroadCasting && id === program && value !== aasGenEd) {
        // }
    }

    private resetEmphasisBox(id, value) {
        const [corePlus, , corePlusPlus] = this.CORE_EMPHASIS_PROGRAMS
        // const [sportsBroadcasting] = CORE_PROGRAMS_DISABLED
        // const [, program] = this.CAREER_KEYS
        if (id === corePlus || id === corePlusPlus) {
            if (id === corePlus && value === this.CORE_EMPHASIS_CHECKED) {
                this.json_data[corePlusPlus] = ""
                this.resetEmphasisSelects(id, value)
                rules = {
                    ...originalRules,
                    ...rulesCorePlusProgram
                }
            } else if (id === corePlusPlus && value === this.CORE_EMPHASIS_CHECKED) {
                this.json_data[corePlus] = ""
                this.resetEmphasisSelects(id, value)
                rules = {
                    ...originalRules,
                    ...rulesCorePlusProgram,
                    ...rulesCorePlusPlusProgram
                }
            } else {
                this.resetEmphasisSelects(id, value)
                rules = {
                    ...originalRules
                }
            }
        }
    }

    private onChange(id: string, value) {
        // console.log("id value:", {id, value})
        // console.log("JsonData:", id, toJS(this.json_data))
        const [campus, program, advisor] = this.CAREER_KEYS
        this.json_data[id] = value
        this.resetEmphasisBox(id, value)
        if (id === campus) {
            this.json_data[program] = ""
            this.json_data[advisor] = ""
            this.json_data[this.START_DATE] = ""
            this.initExtraInfo()
            this.resetEmphisisCheckeds(id)
            this.resetEmphasisSelects(id, value)
        }
        if (id === program) {
            this.json_data[this.START_DATE] = ""
            this.resetEmphisisCheckeds(id)
            this.resetEmphasisSelects(id, value)
        }
        if (id === this.has18Key) {
            this.json_data[this.PARENT_KEY.email] = ""
            this.json_data[this.PARENT_KEY.name] = ""
        }
        this.onInitFillSelectOptions()
        this.props.model.dispatcher2.dispatch()
        // console.log("JsonData:", id, toJS(this.json_data))
        this.forceUpdate()
    }

    public render() {
        const model = this.props.model
        const {
            career_campuses,
            career_programs,
            career_advisors,
            career_dates,
            json_data,
            career_corePlus,
            career_corePlusPlus,
            career_aas_non_sports
        } = this
        const p = {model, onChange: this.onChange, errors: this.errors}
        const r = {onChange: this.onChange, json_data: this.json_data, errors: this.errors}
        const isCoreEmphasisPlus = model.document.json_data.corePlus === CHECKED_YES
        const isCoreEmphasisPlusPlus = model.document.json_data.corePlusPlus === CHECKED_YES
        const [
            ,
            aasBroadCasting,
            mediaSalesMarketing,
            ,
            ,
            aasGenEdOnly,
            aasNonSports
        ] = CORE_PROGRAMS_DISABLED // , audioProduction, filmProduction
        const program = model.document.json_data.program
        const disabledCheckBox =
            program === aasBroadCasting ||
            program === mediaSalesMarketing ||
            program === aasGenEdOnly ||
            program === aasNonSports //  || program === audioProduction || program === filmProduction

        return (
            <React.Fragment>
                {json_data.role ? !json_data.accepted && <Disclosure model={model} /> : null}
                <div className={styles.root}>
                    <h2 className={styles.h2}>CAREER INFORMATION</h2>
                    <div className={styles.form}>
                        <Label text="Campus" required>
                            <Select id="campus" options={career_campuses} {...p} />
                        </Label>

                        <Label text="Program" required>
                            <Select id="program" options={career_programs} {...p} />
                        </Label>
                        <Label text="Emphasis">
                            <fieldset
                                className={styles.noBorder}
                                disabled={!model.document.json_data.campus}>
                                <LabelRadio text="" className={styles.labelRadio}>
                                    <>
                                        <CheckBox
                                            label="Core +"
                                            value="no"
                                            id={"corePlus"}
                                            {...r}
                                            disabled={disabledCheckBox}
                                        />
                                        <CheckBox
                                            label="Core ++"
                                            value="no"
                                            id={"corePlusPlus"}
                                            {...r}
                                            disabled={disabledCheckBox}
                                        />
                                        {model.document.json_data.program === aasNonSports && (
                                            <CustomCheckBox
                                                label="AAS - Non sports"
                                                value="yes"
                                                id={"nonSports"}
                                                {...r}
                                            />
                                        )}
                                    </>
                                </LabelRadio>
                            </fieldset>
                        </Label>
                        {(isCoreEmphasisPlus ||
                            isCoreEmphasisPlusPlus ||
                            this.json_data.program === aasNonSports) && (
                            <div className={styles.emphasisOptions}>
                                <Label text="Program (Emphasis)" required>
                                    <Select
                                        id="corePlusProgram"
                                        options={
                                            model.document.json_data.program &&
                                            model.document.json_data.program === aasNonSports
                                                ? career_aas_non_sports
                                                : career_corePlus
                                        }
                                        {...p}
                                    />
                                </Label>
                            </div>
                        )}
                        {model.document.json_data.corePlusPlus && isCoreEmphasisPlusPlus && (
                            <div className={styles.emphasisOptions}>
                                <Label text="Program (AAS)" required>
                                    <Select
                                        id="corePlusPlusProgram"
                                        options={career_corePlusPlus}
                                        {...p}
                                    />
                                </Label>
                            </div>
                        )}
                        <Label text="Advisor" required>
                            <Select id="advisor" options={career_advisors} {...p} />
                        </Label>

                        <Label text="Starting date" required>
                            <Select id="start_date" options={career_dates} {...p} />
                        </Label>
                        <LabelRadio
                            text="Are you 18 or older?"
                            error={this.errors[this.has18Key]}
                            required>
                            <RadioButton
                                label="Yes"
                                value="yes"
                                group_name={this.has18Key}
                                {...r}
                            />
                            <RadioButton label="No" value="no" group_name={this.has18Key} {...r} />
                        </LabelRadio>
                    </div>
                    {json_data[this.has18Key] && json_data[this.has18Key] !== CHECKED_YES && (
                        <Label text="">
                            <div className={styles.parentOptions}>
                                <h5 className={styles.parentTitle}>Parent Information</h5>
                                <Label text="Name:" className={styles.gap} required>
                                    <Input id="parent_name" {...r} />
                                </Label>
                                <Label text="email" className={styles.gap} required>
                                    <Input id="parent_email" {...r} />
                                </Label>
                            </div>
                        </Label>
                    )}
                    <p className={styles.text}>
                        <span>*</span> Indicates that a response is required
                    </p>
                </div>
            </React.Fragment>
        )
    }
}
