import React, {Component} from "react"
import styles from "./Main.module.css"
import {observer} from "mobx-react"
import {Loader} from "components/Loader"
import {Model} from "../../Model"
import classNames from "classnames"
import {ReactComponent as Icon} from "./img/icon.svg"
import {SURVEY_QUIZ_TAB} from "helper/Methods"
interface Props {
    model: Model
}

@observer
export class Main extends Component<Props, {}> {
    private iframe: HTMLIFrameElement

    private onLoadIframe = () => this.props.model.setIsMainIframeLoading()

    public render() {
        const model = this.props.model
        const token = model.gate.getToken()
        const {onLoadIframe} = this

        if (!model.user) {
            return <h1>Error. No token</h1>
        }

        const url = `${process.env.REACT_APP_PDF_DOMAIN}?form_id=${model.form.form_id}&token=${token}&user_id=${model.user.id}`

        return (
            <div className={styles.root}>
                <div>
                    <h2 className={styles.h2}>{model.form.title}</h2>
                    {model.form &&
                        model.form.form_id === SURVEY_QUIZ_TAB &&
                        model.document.json_data.isSurveyQuizAnswered &&
                        model.document.json_data.surveyQuizResults &&
                        !model.document.json_data.isSurveyQuizRetake && (
                            <button
                                className={classNames(styles.button, styles.buttonNext)}
                                onClick={async () => {
                                    await model.retakeSurveyQuiz()
                                    this.forceUpdate()
                                }}>
                                RETAKE SURVEY QUIZ{" "}
                                <Icon className={styles.icon} width="12" height="12" />
                            </button>
                        )}
                </div>
                <Loader isVisible={!this.props.model.isMainIframeLoading} />
                <iframe
                    id="admissions-pdf"
                    title="PDF Preview"
                    key={Math.random()}
                    src={url}
                    frameBorder={0}
                    width="100%"
                    height="100%"
                    className={styles.iframe}
                    onLoad={onLoadIframe}
                    allowFullScreen
                    ref={(f) => {
                        this.iframe = f as HTMLIFrameElement
                    }}
                />
            </div>
        )
    }
}
