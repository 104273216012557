import React, {Component} from "react"
import {observable} from "mobx"
import {observer} from "mobx-react"
import styles from "./Header.module.css"
import bellusLogo from "./parts/img/beonair-logo-w.png"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"
import {LogoutButton} from "./parts/LogoutButton"
import {MenuButton} from "./parts/MenuButton"
import {HelpButton} from "./parts/HelpButton"
import {Model} from "Model"

type Screen = "mobileScreen" | "desktopScreen"

interface Props {
    onClickNavigation: (e?) => void
    model: Model
}
@observer
export class Header extends Component<Props, {}> {
    @observable
    private screen: "mobileScreen" | "desktopScreen" = "desktopScreen"

    private onChangeScreen() {
        if (window.innerWidth < 660) {
            this.screen = "mobileScreen"
        } else {
            this.screen = "desktopScreen"
        }
    }

    public render() {
        const {onClickNavigation} = this.props
        return (
            <header className={styles.root}>
                <div className={styles.sidebar}>
                    <div className={styles.buttonWrapper}>
                        {this.screen === "mobileScreen" && (
                            <MenuButton onClick={onClickNavigation} />
                        )}
                        {this.screen === "desktopScreen" && (
                            <HelpButton
                                onClick={() => {
                                    console.log("Help button click")
                                }}
                            />
                        )}
                        <LogoutButton
                            onClick={() => {
                                console.log("logout click")
                                this.props.model.signOut()
                            }}
                        />
                    </div>
                </div>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <img src={bellusLogo} alt="bellus-logo" />
                    </h1>
                    <SignatureIcon className={styles.signatureIcon} />
                    <span className={styles.text}>APPLY TO BEONAIR</span>
                </div>
            </header>
        )
    }

    componentDidMount() {
        window.addEventListener("resize", this.onChangeScreen.bind(this))
        this.onChangeScreen()
    }
}
