import moment from "moment"
import {FormID} from "../interfaces"
const formatDateMonthDayYear = (date) => moment(date).format(" MMMM D [,] YYYY")

const formatStandarUSDate = (date) => moment(date).format(" MM/DD/YYYY")

const FORMS_PDF: any = {
    size_rtb: 5,
    size_emph: 6,
    size_aas: 7
}

const YES = "yes"
const NO = "no"
const MANDATORY_FORMS = ["document_for_admission", "document_admission_pkg", "survey_quiz"]

const isEnableSubmitButton = (json_data, forms) => {
    const enableEnrollmentForms = deepCloneOnlyEnabledEnrollmentForms(forms)
    const enableFormsIds = [...Object.keys(enableEnrollmentForms), ...MANDATORY_FORMS]

    const jsonDataFormsIds = Object.keys(json_data.forms || [])
    const filledFormsFromJsonData = []
    for (let i = 0; i < enableFormsIds.length; i++) {
        const enableFormId = enableFormsIds[i]
        for (let j = 0; j < jsonDataFormsIds.length; j++) {
            const jsonDataFormId = jsonDataFormsIds[j]
            if (enableFormId === jsonDataFormId) {
                filledFormsFromJsonData.push(jsonDataFormId)
                break
            }
        }
    }
    // console.log("comparation", {
    //     filled: toJS(filledFormsFromJsonData),
    //     enabled: toJS(enableFormsIds)
    // })
    if (
        json_data.corePlus !== YES &&
        json_data.corePlusPlus !== YES &&
        filledFormsFromJsonData.length > 0
    ) {
        return !(enableFormsIds.length === filledFormsFromJsonData.length)
    } else if (json_data.corePlus === YES && json_data.corePlusPlus !== YES) {
        return !(enableFormsIds.length === filledFormsFromJsonData.length)
    } else if (json_data.corePlusPlus === YES && json_data.corePlus !== YES) {
        return !(enableFormsIds.length === filledFormsFromJsonData.length)
    } else {
        return true
    }
}

const sizeCurrentFoms = (currentJsonDataForms) => {
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (FORMS_PDF.includes(elementKey as FormID)) counter += 1
    }
    return counter
}

const CHECKED_YES = "yes"

const ENROLLMENT_PROGRAMS = [
    "Radio and Television Broadcasting",
    "Hispanic Radio and Television Broadcasting",
    "Digital Media Program (RTB Grad)",
    "Digital Media Program"
]

const CAMPUS_EA_EMP_APC = ["Lombard", "Chicago", "Miami"]

const WILDCARD = "enrollment_agreement_"

const deleteEnrollmentForms = (jsonData) => {
    delete jsonData.enrollment_agreement_aas
    delete jsonData.enrollment_agreement_emp_apc
    delete jsonData.enrollment_agreement_emp_apc_lc
    delete jsonData.enrollment_agreement_emp_apc_miami
    delete jsonData.enrollment_agreement_hispanic_rtb
    delete jsonData.enrollment_agreement_rtb
    delete jsonData.enrollment_agreement_rtb_lc
    delete jsonData.enrollment_agreement_rtb_miami
}

const deepCloneOnlyEnabledEnrollmentForms = (forms) => {
    let newForms = {}
    forms
        .filter(({form_id, enabled}) => form_id.includes(WILDCARD) && enabled === true)
        .sort((a, b) => a.order - b.order)
        .forEach((aForm) => {
            newForms[aForm.form_id] = {
                enabled: aForm.enabled,
                title: aForm.title,
                type: aForm.type,
                order: aForm.order
            }
        })
    return newForms
}

const deepCloneCurrentEnabledForms = (json_data, forms) => {
    const jsonDataForms = {...json_data.forms}
    let newForms = {}
    forms
        .filter(({form_id, enabled}) => form_id.includes(WILDCARD) && enabled === true)
        .sort((a, b) => a.order - b.order)
        .forEach((aForm) => {
            newForms[aForm.form_id] = {
                enabled: aForm.enabled,
                title: aForm.title,
                type: aForm.type,
                order: aForm.order
            }
        })
    deleteEnrollmentForms(jsonDataForms)
    return {...jsonDataForms, ...newForms}
}

const REFILL_FORMS: FormID[] = [
    "enrollment_agreement_rtb",
    "enrollment_agreement_hispanic_rtb",
    "enrollment_agreement_rtb_lc",
    "enrollment_agreement_rtb_miami",
    "enrollment_agreement_emp_apc",
    "enrollment_agreement_emp_apc_lc",
    "enrollment_agreement_emp_apc_miami",
    "enrollment_agreement_aas"
]

const removeJsonDataForms = (currentJsonDataForms) => {
    return Object.keys(currentJsonDataForms)
        .filter((key) => !REFILL_FORMS.includes(key as FormID))
        .reduce((obj, key) => {
            obj[key] = currentJsonDataForms[key]
            return obj
        }, {})
}

const removeSchedulesIdsFromJsonData = (json_data) => {
    delete json_data.emp_schedule_date
    delete json_data.emp_lc_schedule_date
    delete json_data.emp_m_schedule_date
    delete json_data.rtb_hisp_schedule_date
    delete json_data.rtb_lc_schedule_date
    delete json_data.rtb_schedule_date
    delete json_data.rtb_m_schedule_date

    delete json_data.rtb_meet_date
    delete json_data.rtb_hisp_meet_date
    delete json_data.rtb_lc_meet_date
    delete json_data.rtb_m_meet_date
    delete json_data.emp_meet_date
    delete json_data.emp_lc_meet_date
    delete json_data.emp_m_meet_date

    delete json_data.aas_m_meet_date

    delete json_data.rtb_schedule_end_date
    delete json_data.rtb_hisp_end_date
    delete json_data.rtb_lc_end_date
    delete json_data.rtb_m_end_date

    delete json_data.emp_end_date
    delete json_data.emp_lc_end_date
    delete json_data.emp_m_end_date

    delete json_data.aas_m_end_date
}

const CORE_PROGRAMS_DISABLED = [
    "Sports Broadcasting",
    "Associate of Applied Science in Radio and Television Broadcasting",
    "Media Sales & Marketing",
    "Audio Production",
    "Film & Video Production",
    "Associates in Applied Science (Gen-Ed Only)",
    "Associates in Applied Science (Non sports)"
]

const SURVEY_QUIZ_TAB = "survey_quiz"

const COUNTER_DOCUMENT_SENT = {
    firstTime: 1,
    seccondTime: 2
}

export {
    formatDateMonthDayYear,
    formatStandarUSDate,
    isEnableSubmitButton,
    sizeCurrentFoms,
    FORMS_PDF,
    CHECKED_YES,
    ENROLLMENT_PROGRAMS,
    CAMPUS_EA_EMP_APC,
    deepCloneCurrentEnabledForms,
    removeJsonDataForms,
    removeSchedulesIdsFromJsonData,
    CORE_PROGRAMS_DISABLED,
    YES,
    NO,
    SURVEY_QUIZ_TAB,
    COUNTER_DOCUMENT_SENT
}
